import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { navigate } from '@reach/router'
import { generateSlug } from '../helpers/slug'
import LocaleContext from '../components/Locale'
import App from '../components/App'
import Main from '../components/Main'
import Wrapper from '../components/Wrapper'
import Grid from '../components/Grid'
import CardKitchen from '../components/CardKitchen'
import CardMediaLess from '../components/CardMediaLess'
import * as Accessa from 'accessa'
import HeroVariant from '../components/HeroVariant'
import { CardSecondary } from '../components/Card'
import CardRecipe from '../components/CardRecipe'
import { HeadingWithLine } from '../components/Heading'
import SEO from '../components/SEO'
import lang from '../../static/lang.json'
import vars from '../lib/styled'

const Index = ({ data }) => {
  const { categories, homepage } = data
  const [slidesToShow, setSlidesToShow] = useState(3)
  const locale = homepage.node_locale.toLowerCase()

  const calculateSliderSize = () => {
    const width = document.body.clientWidth
    let size = 3
    if (width < 576) {
      size = 1
    } else if (width < 768) {
      size = 2
    }

    setSlidesToShow(size)
  }

  useEffect(() => {
    window.addEventListener('resize', calculateSliderSize)
    setTimeout(() => window.dispatchEvent(new Event('resize')), 0)
  })

  return (
    <App>
      {/** SEO and multi-site nav */}
      <SEO {...homepage} />
      <LocaleContext.Consumer>
        {() => (
          <Helmet>
            {homepage.node_locale !== 'en-CA' && (
              <link rel="alternate" href="/en-ca" hrefLang="en-CA" />
            )}
            {homepage.node_locale !== 'fr-CA' && (
              <link rel="alternate" href="/fr-ca" hrefLang="fr-CA" />
            )}
            {homepage.node_locale !== 'en-US' && (
              <link rel="alternate" href="/en-us" hrefLang="en-US" />
            )}
          </Helmet>
        )}
      </LocaleContext.Consumer>
      <HeroVariant {...homepage} />

      <Wrapper>
        <Main marginless>
          <div>
            <HeadingWithLine>{homepage.productTitle}</HeadingWithLine>
            {slidesToShow && (
              <Accessa.Carousels.Slideshow
                redirect={navigate}
                data={categories.nodes.map((category) => {
                  const slug = generateSlug(
                    [
                      homepage.node_locale,
                      lang.products[homepage.node_locale.toLowerCase()],
                      category.title,
                    ].join('/'),
                  )

                  category.href = `/${slug}`
                  return category
                })}
                component={(category) => (
                  <CardSecondary
                    fullWidth
                    id={category.elementId}
                    name={category.title}
                    image={category.image}
                    key={category.contentful_id}
                    linkText={
                      lang.productsView[homepage.node_locale.toLowerCase()]
                    }
                  />
                )}
              />
            )}
          </div>
        </Main>
      </Wrapper>

      {
        /** US website doesn't have recipes */
        homepage.recipes && (
          <div
            style={{
              background: `linear-gradient(${vars.primary} 15%,${vars.primaryHover}`,
            }}
          >
            <Main marginless>
              <div style={{ backgroundColor: vars.primary }}>
                <HeadingWithLine inverse>
                  {homepage.recipeTitle}
                </HeadingWithLine>
              </div>

              <Grid style={{ marginBottom: '4rem' }}>
                {homepage.recipes.map((recipe) => (
                  <CardRecipe
                    {...recipe}
                    button={
                      lang.recipesView[homepage.node_locale.toLowerCase()]
                    }
                    key={recipe.contentful_id}
                    url={generateSlug(
                      `${homepage.node_locale}/recipes/${recipe.title}`,
                    )}
                  />
                ))}
              </Grid>
              <CardKitchen {...homepage.card} />
            </Main>
          </div>
        )
      }

      {
        /** US website doesn't have blocks either */
        homepage.blocks && (
          <Wrapper offset>
            <Main marginless>
              <Grid>
                {homepage.blocks.map((block) => (
                  <CardMediaLess key={block.contentful_id} {...block} />
                ))}
              </Grid>
            </Main>
          </Wrapper>
        )
      }
    </App>
  )
}

export default Index

export const query = graphql`
  query queryHomepageByNodeLocale($locale: String) {
    homepage: contentfulHomepage(node_locale: { eq: $locale }) {
      title
      description {
        description
      }
      node_locale
      button
      buttonDestination
      productTitle
      recipeTitle
      image {
        title
        fluid(maxWidth: 2200, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      card {
        contentful_id
        title
        subtitle
        button
        url
        body {
          childContentfulRichText {
            html
          }
        }
        image {
          title
          fluid(maxWidth: 2200) {
            ...GatsbyContentfulFluid
          }
        }
      }
      blocks {
        contentful_id
        title
        subtitle
        button
        url
      }
      recipes {
        contentful_id
        title
        serves
        time
        image: photo {
          title
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    categories: allContentfulCategory(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        title
        contentful_id
        image {
          title
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
